import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import cx from 'classnames';
import style from './reviews.module.scss';
import { Layouts } from '../components/components';
import { SliderBlock, PictureWithText } from '../modules/modules';
import { SliderBlocksType, PictureWithTextType } from '../lib/constants/enums';
import { StrapiBlocksType, StrapiMainPagesNames } from '../lib/constants/strapi';
import { StrapiPagesBlocksReviewSelectItem } from '../graphql-types';
import { useStrapiPage } from '../lib/hooks/useStrapiPage';

const ReviewsPage: React.FC = () => {
  const { IndexLayout, Page, LayoutBG, Container } = Layouts;
  const { currentPage } = useStrapiPage(StrapiMainPagesNames.reviews);
  const [currentProgram, setCurrentProgram] = useState<StrapiPagesBlocksReviewSelectItem | null>(null);
  const [isOpenSelect, setOpenSelect] = useState<boolean>(false);

  useEffect(() => {
    if (currentPage && currentPage.blocks && !currentProgram) {
      const findSelectBlock = currentPage.blocks.find(el => el?.blockTypes?.blockTypes === StrapiBlocksType.reviewsSelect);
      if (findSelectBlock) setCurrentProgram(() => findSelectBlock.ReviewSelectItem![0]);
    }
  }, [currentPage]);

  return (
    <IndexLayout>
      <Page>
        {currentPage && currentPage.blocks ? (
          <LayoutBG className={style.reviewsBg}>
            <Container className={style.reviewsContainer}>
              <div className={style.reviewsContent}>
                <h2 className={style.reviewsTitle}>Отзывы участников</h2>
                {currentPage &&
                  currentPage.blocks &&
                  currentProgram &&
                  currentPage.blocks.map((block, index) => {
                    const blockType = block?.blockTypes?.blockTypes;
                    switch (blockType) {
                      case StrapiBlocksType.reviewsSelect:
                        return (
                          <div className={style.selectBlock} key={index}>
                            <span>Выберете программу: </span>
                            <div className={style.select}>
                              <div className={style.selectHead}>
                                <h3>{currentProgram && currentProgram.label}</h3>
                                {/* <div
                                  className={cx(style.selectArrow, { [style.selectArrowActive]: isOpenSelect })}
                                  onClick={() => setOpenSelect(prev => !prev)}
                                /> */}
                              </div>
                              <div className={cx(style.selectList, { [style.selectListActive]: isOpenSelect })}>
                                {block?.ReviewSelectItem &&
                                  block.ReviewSelectItem.map((selectItem, index) => {
                                    if (selectItem?.type === currentProgram?.type) return null;
                                    return (
                                      <div
                                        onClick={() => {
                                          setCurrentProgram(() => selectItem);
                                          setOpenSelect(prev => !prev);
                                        }}
                                        className={style.selectListItem}
                                        key={index}
                                      >
                                        {selectItem?.label}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        );
                      case StrapiBlocksType.reviewSlider:
                        return (
                          <SliderBlock
                            data={{ ...block, ReviewSlide: block?.ReviewSlide?.filter(slide => slide?.type === currentProgram?.type) }}
                            type={SliderBlocksType.reviews}
                            key={index}
                          />
                        );
                      case StrapiBlocksType.reviewImageSlider:
                        return (
                          <SliderBlock
                            data={{
                              ...block,
                              ReviewImageSlide: block?.ReviewImageSlide?.filter(slide => slide?.type === currentProgram?.type),
                            }}
                            type={SliderBlocksType.reviewImage}
                            key={index}
                          />
                        );
                      default:
                        return null;
                    }
                  })}
              </div>
            </Container>
          </LayoutBG>
        ) : (
          <div className={style.loader}>
            <Spin />
          </div>
        )}
        {currentPage &&
          currentPage.blocks &&
          currentPage.blocks.map((block, index) => {
            const findDescriptionBlock = block?.blockTypes?.blockTypes === StrapiBlocksType.descriptionBlock;
            if (findDescriptionBlock)
              return (
                <PictureWithText
                  description={block?.description || ''}
                  image={(block?.picture && block.picture.url) || ''}
                  type={PictureWithTextType.descriptionBlock}
                  key={index}
                />
              );
          })}
      </Page>
    </IndexLayout>
  );
};

export default ReviewsPage;
